
/*------------------------------------------------------------------
* Project:        Suchana
* Author:         CN-InfoTech
* URL:            hthttps://themeforest.net/user/cn-infotech
* Created:        10/15/2019
-------------------------------------------------------------------

- // TABLE OF CONTENTS // -

========================================================================
 

 # Landing Page
 ===============================================
 1) Default
 2) Header
 3) Banner
 4) Blog Section
 5) Blog Single
 6) Instagram
 7) Footer
 8) About Us
 9) Contact us  
 10) Error Page
 11) Back to Top
 12) Shop
 13)Faq
 14)Coming Soon


/* ========================================= */
/*            Default           */
/* ========================================= */

body.home-default, body.home-1.home-2{position: relative;}

body.home-default:before, body.home-1.home-2:before {
    background: url(../images/body1.png) no-repeat;
    background-position: center;
    background-size: 100%;
    background-attachment: fixed;    
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
}


/* ====================== */
/*         Header         */
/* ====================== */

header#inner-navigation {
    width: 100%;
    clear: both;
}

.home-1.home-2 #inner-navigation{padding: 0;}

.offer.text-center {
    padding: 10px 0;
    background: #f1f1f170;
    border-bottom: 1px solid #f1f1f1;
}

.contact-info p{
  display: inline-block;
  margin:0 15px 0 0;
}

.offer i{
  padding-right: 5px;
}

.login-btn a{
  color: #5d5c5c;
  font-size: 14px;
  margin-left: 15px;
  font-weight: 400;
}

.logo h1{
    font-size: 48px;
    margin: 0;
    line-height: 1;
}

.logo a{padding: 0;}

.logo a:hover{
    background:none!important;
    opacity: 0.8; 
}

.home-1 #inner-navigation .navbar.navbar-sticky-in .logo img{width:inherit;}

#inner-navigation .navbar{ background: #fff; margin: 0; padding: 21px 0; border: none;}

.home-1.home-2 #inner-navigation .navbar{position: inherit; background: transparent;}

#inner-navigation .navbar.navbar-sticky-in {
    box-shadow: 0 0 5px #ccc;
    transition: all ease-in-out 0.5s; 
}

#inner-navigation .nav.navbar-nav li ul{border-top-color:  #333a65;}

.navbar.navbar-default ul.nav.navbar-nav{margin-top: 21px;}

/*.navbar.navbar-sticky-in.navbar-default ul.nav.navbar-nav{margin-top: 16px;  transition: all ease-in-out 0.3s;}*/

.home-1 .navbar.navbar-sticky-in.navbar-default ul.nav.navbar-nav{margin-top: 22px; }

.navbar.navbar-default.sticky-nav ul.nav.navbar-nav{margin-top: 2px;  transition: all ease-in-out 0.3s;}

.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav li:last-child {border-bottom: none; } 

.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav li a, #inner-navigation .nav.navbar-nav > li > a {
  font-size: 16px;
  padding:0;
  line-height: inherit;
  position: relative; 
  transition: all ease-in-out 0.3s; 
  background-color: transparent;
  background-image: none!important;
  padding-bottom: 0px!important;
}

.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav li.active a, .navbar.navbar-default .navbar-collapse ul.nav.navbar-nav li a:hover {
  background: none!important;
  color:#333a65!important;
  transition: all ease-in-out 0.5s; 
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover{
  color:#333a65!important;
}

.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav li:before {display: none; }

.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav li, #inner-navigation .nav.navbar-nav li {
  padding: 0;
  margin-bottom: 0;
  margin-left: 30px; 
}

@media (max-width: 1200px) and (min-width: 1024px){
  .navbar.navbar-default .navbar-collapse ul.nav.navbar-nav li a, 
  #inner-navigation .nav.navbar-nav > li > a{font-size: 14px; }
}

@media(max-width: 1100px){
  .navbar.navbar-default .navbar-collapse ul.nav.navbar-nav li, #inner-navigation .nav.navbar-nav li{
    margin-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .navbar.navbar-default .navbar-header {
    margin-bottom: 0px;
    margin-top: 0px;
    display: inline-block;
  }

  .navbar.navbar-default .navbar-collapse ul.nav.navbar-nav li a, #inner-navigation .nav.navbar-nav > li > a{font-size: 13px;}

  .navbar.navbar-default .navbar-collapse ul.nav.navbar-nav li {
    margin-bottom: 0;
    padding: 0; 
  } 

   .navbar.navbar-default .navbar-collapse ul.nav.navbar-nav li:first-child {
    margin-left: 0; 
  } 
}

@media (max-width: 991px) and (min-width: 812px){
  #inner-navigation .slicknav_btn,  #inner-navigation .navbar-sticky-in.navbar-default .slicknav_btn{right: 47px;}
}

@media (max-width: 991px){
  .offer.text-center{display: none;}
  #inner-navigation .slicknav_btn, #inner-navigation .navbar-sticky-in.navbar-default .slicknav_btn{top: 45px; background-image: none;}

  .slicknav_btn.slicknav_open:before, .slicknav_btn.slicknav_collapsed:before{
      color: #333a65;
  }

   #inner-navigation .slicknav_nav{top: 107px; z-index: 2;}

   .slicknav_nav a.mt_search{display: none;}

   #inner-navigation .navbar-sticky-in.navbar-default .slicknav_nav{top: 72px;}

  #inner-navigation .slicknav_nav i.fa.fa-angle-down{display: none;}

}


@media only screen and (max-width: 767px) {
  .navbar.navbar-default .navbar-toggle {
    border-color: #333a65;
    background-color: #333a65; 
    margin-top: 10px; 
    margin-right: 0;
  }

  .navbar.navbar-default .navbar-toggle .icon-bar {
    background-color: #FFFFFF; 
  } 

  .navbar.navbar-default .navbar-toggle:hover {
      border-color: #FFFFFF;
      background-color: #FFFFFF; 
  }
  .navbar.navbar-default .navbar-toggle:hover .icon-bar {
    background-color: #333a65; 
  } 

  .navbar.navbar-default .navbar-collapse {
    background: #1F1D26;
    border: none;
    margin-top: 15px;
    max-height: 415px; 
  } 

  .navbar.navbar-default .navbar-collapse ul.nav.navbar-nav li a, #inner-navigation .nav.navbar-nav > li > a{
    color: #B6B3C4!important;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1.5;
    }

  .navbar.navbar-default ul.nav.navbar-nav{margin: 0;}

  .navbar.navbar-default .navbar-collapse ul.nav.navbar-nav li{margin: 0;}

  .navbar.navbar-default .navbar-collapse ul.nav.navbar-nav li a {
    padding: 10px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05); 
  }

  .navbar.navbar-default .navbar-collapse ul.nav.navbar-nav li:last-child a{border-bottom: 0;}
}

@media only screen and (max-width: 639px) {

  .navbar.navbar-default .navbar-collapse {
    max-height: 225px; 
  } 

}

@media(max-width: 400px){
  #inner-navigation .slicknav_nav{top: 96px;}
}

@media(max-width: 359px){
  #inner-navigation .slicknav_btn, #inner-navigation .navbar-sticky-in.navbar-default .slicknav_btn{top: 39px;}

}

/* Main Menu Dropdown */

#inner-navigation .logo-wrapper {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

#inner-navigation ul.nav-mini{
    padding-top: 3px;
    padding-right: 13px;
}

#inner-navigation ul.nav-mini li {
    display: inline-block;
    margin-right: 10px;
    font-size: 13px;
    background: #00bcd4;
    border-radius: 5px;
    padding: 2px 10px;
}

#inner-navigation ul.nav-mini li:last-child{margin-right: 0px;}

#inner-navigation ul.nav-mini li i{color: #fff; padding-right: 5px;}

#inner-navigation .nav.navbar-nav {
    float: left;
    transition: all ease-in-out 0.5s;
}

.home-1.home-2 #inner-navigation .nav.navbar-nav{float: none!important; display: inline-block; margin-top: 30px;}

#inner-navigation .nav.navbar-nav > li:last-child > a:after {
    display: none;
}

#inner-navigation .nav.navbar-nav li ul {
    z-index: 99999;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 40px;
    left: 0;
    width: 200px;
    display: none;
    background-color: #fbfbfb;
    border: none;
    border-top: 2px solid #333a65; 
    -webkit-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.4);
}

#inner-navigation .nav.navbar-nav li ul li a {
    border-bottom: 1px solid #E6E6E6;
    display: block;
    padding: 14px 20px;
    font-size: 13px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #444;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 1.25;
}

#inner-navigation .nav.navbar-nav li ul li a i {
    float: right;
}

#inner-navigation .nav.navbar-nav li ul li a:hover{
  background: transparent;
  border-radius: 0px;
  color: #333a65!important;
}

#inner-navigation .nav.navbar-nav li > ul > li:last-child > a,
#inner-navigation .navbar-nav li > ul > li > ul > li:last-child > a {
    border-bottom: 0;
}

#inner-navigation .nav.navbar-nav li ul li:hover ul li a {
    color: #444;
}

#inner-navigation .nav.navbar-nav li ul li:hover a, .nav.navbar-nav li ul li ul li:hover a {
    padding-left: 25px;
    padding-right: 15px;
    color: #333a65;
}

#inner-navigation .nav.navbar-nav li ul li ul {
    position: absolute;
    left: 198px;
    top: -2px;
}

#inner-navigation .nav.navbar-nav li ul li:hover ul li a {
    padding-left: 15px;
    padding-right: 15px;
}

#inner-navigation .nav.navbar-nav li ul li ul li:hover a {
    padding-left: 20px;
    padding-right: 10px;
}

#inner-navigation .nav.navbar-nav li ul li {
    display: block;
    text-align: left;
    margin-left: 0;
}


@media only screen and (max-width: 991px) {
    .logo-wrapper {
        width: 50%;
    }

    .logo img{float: left;}
    #inner-navigation .navbar{position: inherit;}
    
    #inner-navigation .navbar-nav-wrapper {
        width: 0%;
    }
    #inner-navigation .navbar {
        margin: 0;
        -webkit-transition: none;
        -moz-transition: none;
        -ms-transition: none;
        -o-transition: none;
        transition: none;
    }
    #inner-navigation .navbar-brand {
        line-height: 30px;
        -webkit-transition: none;
        -moz-transition: none;
        -ms-transition: none;
        -o-transition: none;
        transition: none;
    }
    #inner-navigation .navbar-arrow > ul > li .arrow-indicator,
    #inner-navigation .navbar-arrow ul ul > li .arrow-indicator {
        display: none;
    }

    #inner-navigation ul.nav-mini{padding-right: 0;}

    #inner-navigation ul.nav-mini li{margin-right: 5px; padding: 2px 6px;}

    .home-1.home-2 #inner-navigation .nav.navbar-nav{display: none;}
}

@media (max-width: 767px) {

    #inner-navigation .navbar-nav .open .dropdown-menu > li > a {
        line-height: 23px;
    }
}

@media(max-width: 400px){
  .logo img{width: 85%;}
}
/*Fullpage search*/

#search {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999;
    -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
    -webkit-transform: translate(0px, -100%) scale(0, 0);
  -moz-transform: translate(0px, -100%) scale(0, 0);
  -o-transform: translate(0px, -100%) scale(0, 0);
  -ms-transform: translate(0px, -100%) scale(0, 0);
  transform: translate(0px, -100%) scale(0, 0);
    opacity: 0;
}

#search.open {
    -webkit-transform: translate(0px, 0px) scale(1, 1);
    -moz-transform: translate(0px, 0px) scale(1, 1);
  -o-transform: translate(0px, 0px) scale(1, 1);
  -ms-transform: translate(0px, 0px) scale(1, 1);
  transform: translate(0px, 0px) scale(1, 1); 
    opacity: 1;
}

#search input[type="search"] {
    position: absolute;
    top: 35%;
    width: 50%;
    color: #666;
    background: transparent;
    font-size: 48px;
    font-weight: 300;
    text-align: center;
    border-width: 0 0 1px;
    margin: 0px auto;
    padding: 20px 30px;
    outline: none;
    margin-bottom: 30px;
    height: 82px;
    left: 0;
    right: 0;
    border-radius: 0;
}
#search .btn {
    position: absolute;
    top: 55%;
    left: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #333a65;
    padding: 10px 30px;
    border-radius: 26px;
    border: none;
    font-size: 18px;
}
#search .close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: #fff;
    background-color: #333a65;
    border-color: #333a65;
    opacity: 1;
    padding: 10px 17px;
    font-size: 27px;
}

/*====================== */
/*         Banner         */
/* ====================== */

#mt_banner {position: relative; padding: 0; height: 452px!important; margin-top: 107px;}

#mt_banner.single-slider{height: 450px!important; margin-top: 0;}

span.featured-label {
    background: #161b3d;
    border-radius: 20px;
    padding: 10px 15px;
    color: #fff;
}

#mt_banner .banner_caption_text {
    left: 0;
    position: absolute;
    top: 10.25%;
    width: 50%;
    z-index: 1;
    background: #fff;
    padding: 40px 40px 37px;
    margin: 0 0 10vh 35%;
    box-shadow: 0 0 25px #cccccc40;
    border-radius: 0 26px;
}

#mt_banner .banner_caption_text h1 {
  color: #161b3d;
  margin:0;
  position: relative;
  font-weight: 800;
  font-size: 45px; 
  margin-bottom: 8px;
  line-height: 1.5;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-height: 4.5em;
}

#mt_banner .banner_caption_text h1 a{
  background: linear-gradient(to right, #333a65, #333a65);
  color: #333a65; 
  background-position: left 87%;
  background-repeat: no-repeat;
  transition: all ease-in-out 0.5s;
  background-size: 0px 3px;
  padding-bottom: 6px;
}

#mt_banner .banner_caption_text h1 a:hover {
  text-decoration: none !important;
  background-size: 100% 3px;
  transition: all ease-in-out 0.5s;
}

#mt_banner span.featured-label {
    background: #161b3d;
    border-radius: 26px;
    padding: 10px 20px;
    color: #fff;
    margin-bottom: 20px;
    display: inline-block;
}

#mt_banner .banner_caption_text .item-meta{width: 80%;}

#mt_banner .banner_caption_text .item-meta a{
    font-size: 16px;
    font-weight: 700;
    position: relative;
    z-index: 2;
    text-align: left;
    line-height: 1.5;
    background-image: linear-gradient(to right, #333a65, #333a65);
    padding-bottom: 5px;
}

#mt_banner.single-slider .banner_caption_text {
    left: 0;
    top: 14%;
    width: 70%;
    z-index: 1;
     background: transparent; 
    padding: 40px;
     box-shadow: none; 
    border-radius:0;
    right: 0;
    margin: 0 auto;
    text-align: center;
} 

#mt_banner.single-slider .banner_caption_text h1 a, #mt_banner.single-slider .banner_caption_text .item-meta a{
  color: #fff;
  background-image:linear-gradient(to right, #fff, #fff); 
}

#mt_banner.single-slider .banner_caption_text .item-meta{
  width: 100%;
}

/* Slider Section */
.swiper-container {height: 100%; margin: 60px; }

.swiper-slide {overflow: hidden; }

.slide-inner {
  position: absolute;
  width: calc(50% - 45px);
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center; 
  border-radius: 0 26px 26px 0;
  overflow: hidden;
}

.home-1.home-2 .slide-inner{border-radius: 0;}

#mt_banner.single-slider .slide-inner{width: 100%;}

.slide-inner:before {
    content: '';
    background: #0000005a;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    /* z-index: -1; */
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev,
.swiper-button-prev, .swiper-container-rtl .swiper-button-next{
  background-image: none !important; }

.swiper-button-next:before, .swiper-container-rtl .swiper-button-prev:before,
.swiper-button-prev:before, .swiper-container-rtl .swiper-button-next:before {
  content: '\f105';
  font-family: 'fontawesome';
  font-size: 54px;
  position: absolute;
  right: 25px;
  color: #fff; 
}

.swiper-button-prev:before, 
.swiper-container-rtl .swiper-button-next:before {
  content: '\f104';
  left: 25px; 
}

.swiper-button-next:before,  
.swiper-container-rtl .swiper-button-next:before{
  color: #161b3d;
}

#mt_banner.single-slider .swiper-button-next:before,  
#mt_banner.single-slider .swiper-container-rtl .swiper-button-next:before{
  color: #fff;
} 


/*innerpage*/
#mt_banner.innerbanner{height: 480px!important;}

#mt_banner.innerbanner .featured-image {
    position: absolute;
    z-index: -3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: calc(50% - 50px);
    border-radius: 0;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
}

#mt_banner.innerbanner .banner_caption_text{
    margin: 0 0 0 40%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 0 0 25px #cccccc40;
    border-radius: 0 26px;
    background: #fff;
    padding: 40px;
    left: inherit;
}

/*innerpage2*/

#mt_banner.innerbanner .featured-bg {
    position: absolute;
    z-index: -3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: calc(50% - 50px);
    border-radius: 0;
}

/*tag detail*/

.tag #mt_banner.innerbanner{height: 370px!important;}

.tag #mt_banner.innerbanner .banner_caption_text{top: 10%;}

.tag #mt_banner.innerbanner .banner_caption_text p.para{font-size: 18px; margin-bottom: 0;}

/*author*/

#mt_banner.innerbanner.author .banner_caption_text {
    margin: 0 auto;
    width: 70%;
    left: 0;
    right: 0;
    top: 14%;
}

#mt_banner.innerbanner.author .author-main{width: 30%; float: left;}

#mt_banner.innerbanner.author .author-main img {
    width: 100px;
    height: 100px;
    border-radius:50%;
}

#mt_banner.innerbanner.author .author-main h4{margin: 12px 0;}

.author-social.follow_us .social_icons li{padding: 0 2px;}

.author-social.follow_us .social_icons li a {
    height: 30px;
    width: 30px;
    line-height: 2.4;
    font-size: 13px;
}

.author-social.follow_us .social_icons li a i{color: #fff;}

#mt_banner.innerbanner.author .b-content{padding: 22px 0;}

@media(min-width:1601px){
  #mt_banner .banner_caption_text{width: 42%; margin-left: 42%;}
}

@media(min-width:1500px) and (max-width: 1600px){
  #mt_banner .banner_caption_text{width: 46%;}
}

@media(max-width: 1100px){
  #mt_banner .banner_caption_text{top: 9%;}

  #mt_banner .banner_caption_text h1{font-size: 40px;}

  .tag #mt_banner.innerbanner .banner_caption_text{top: 10%;}

  #mt_banner.innerbanner .featured-image{width: calc(55% - 50px);}

  #mt_banner .banner_caption_text{top: 8%;}
}

@media(max-width: 991px){

  #mt_banner{margin-top: 0px;}

  #mt_banner.single-slider .banner_caption_text{width: 90%;}

  #mt_banner .banner_caption_text{
    top: 28%;
    width: 80%;
    right: 0;
    margin: 0 auto;
    text-align: center;
    padding: 0;
    background: transparent;
    box-shadow: none;
    color: #fff;
  }
  #mt_banner .banner_caption_text a, #mt_banner .banner_caption_text h1 a{color: #fff;}

  .slide-inner, #mt_banner .banner_caption_text .item-meta{width: 100%; transform: inherit!important; border-radius: 0;}

  .swiper-button-next.swiper-button-white:before, .swiper-container-rtl .swiper-button-next.swiper-button-white:before{color: #fff;}
  
  #mt_banner.innerbanner .banner_caption_text{margin-left: 24%; right: inherit; width: 70%; top: 14%; text-align: left;}

  #mt_banner.innerbanner .banner_caption_text, #mt_banner.innerbanner .banner_caption_text a, 
  #mt_banner.innerbanner .banner_caption_text h1 a{color: #333a65}

  .tag #mt_banner.innerbanner .banner_caption_text{top: 17%;}

 #mt_banner.innerbanner.author .banner_caption_text{width: 90%;}

 #mt_banner.innerbanner.author .author-main{width: 35%;}

 #mt_banner.innerbanner.author .b-content{padding: 0;}
}

@media(max-width: 811px){
  #mt_banner.innerbanner .banner_caption_text{top: 8%;}

  #mt_banner.single-slider .banner_caption_text{top: 12%;}
}

@media(max-width: 767px){
  #mt_banner .banner_caption_text{top: 24%;}

  #mt_banner.innerbanner .featured-image{width: 100%;}
  #mt_banner.innerbanner .banner_caption_text{margin: 0 auto; left: 0; right: 0; width: 80%; top: 15%;}
}

@media(max-width: 735px){

  #mt_banner .banner_caption_text h1{font-size: 32px;}

  #mt_banner.innerbanner .banner_caption_text{width: 90%;}

  #mt_banner.innerbanner.author .banner_caption_text{padding: 20px;}

  #mt_banner.innerbanner .featured-bg, #mt_banner.innerbanner.author .featured-bg{width: 100%;}

  #mt_banner.innerbanner, .tag #mt_banner.innerbanner
  {background: linear-gradient(45deg,#e77842,#f7c068); padding: 20px; height: auto!important;}

  #mt_banner.innerbanner .featured-bg{display: none;}

  #mt_banner.innerbanner .banner_caption_text, .tag #mt_banner.innerbanner .banner_caption_text{position: inherit; width: 100%; top: inherit;}

  #mt_banner.innerbanner.author .author-main{width: 100%; margin-bottom: 25px;}

  #mt_banner.innerbanner.author .b-content{text-align: center;}
}

@media(max-width: 639px){
  #mt_banner{height: 380px!important;}
  #mt_banner .banner_caption_text {
    top: 24%;
    width: 90%;
  }

  #mt_banner.single-slider .banner_caption_text{width: 100%;}

  .swiper-button-next, .swiper-button-prev,
  .swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white,
  .swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white{display: none;}

}

@media(max-width: 567px){

  #mt_banner.single-slider .banner_caption_text{padding:20px;}
  #mt_banner.innerbanner .banner_caption_text{padding:30px;}

  #mt_banner.innerbanner .banner_caption_text h1{font-size: 28px;}
}

@media(max-width: 360px){

  #mt_banner .banner_caption_text{top: 18%;}
  #mt_banner .banner_caption_text h1{font-size: 32px;}

  #mt_banner.single-slider .banner_caption_text{top: 8%;}
}

/* ============================= */
/*          Blog Section         */
/* ============================= */

section#mt_blog .blog_post_sec.fullwidth{width: 80%; margin: 0 auto;}


#mt_blog .blog-post_wrapper{
    position: relative;
    z-index: 0;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 420px; 
    transition: all ease-in-out 0.5s;
    border-radius: 0 26px;
    width: 100%;
}

#mt_blog .blog-post_wrapper:hover {
    transition: all ease-in-out 0.5s;
    transform: translateY(-5px);
}

#mt_blog .blog-post_wrapper.image-wrapper:before {
    content: '';
    background: #00000057;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}

#mt_blog .blog-post_wrapper.image-wrapper .post-content{
    position: absolute;
    top: 0;
    padding: 20px 30px;
}


#mt_blog .blog-post_wrapper .post-date p{
    margin: 10px 0 18px;
    font-size: 15px;
    font-weight: 600; 
}


#mt_blog .blog-post_wrapper .post-date p a{color: #fff;}

#mt_blog .blog-post_wrapper.front-wrapper{
  padding: 20px 30px;
}
#mt_blog .blog-post_wrapper.front-wrapper.bg-gray a{
  color: #333a65; 
  background: linear-gradient(to right, #333a65, #333a65);
  background-position: left 87%;
  background-repeat: no-repeat;
  transition: all ease-in-out 0.5s;
  background-size: 0px 3px;
  padding-bottom: 6px;
}

#mt_blog .blog-post_wrapper.front-wrapper.bg-gray a:hover{
  text-decoration: none !important;
  background-size: 100% 2px;
  transition: all ease-in-out 0.5s;
}

.bg-gray{
  background-color: #eaedf6;
  background-image: linear-gradient(20deg,#e2e5ed,#f1f4f4);
}


.bg-orange{
  background-color: #e77842;
  background-image: linear-gradient(45deg,#e77842,#f7c068);
}

.bg-red{
  background-color: #ff5858;
  background-image: linear-gradient(1deg,#f57171,#c11919);
}

.bg-green{
  background-color: #047b15;
  background-image: linear-gradient(19deg,#047b15,#30ca43);
}

.bg-navy {
    background-color: #6d77aa;
    background-image: linear-gradient(45deg,#6d77aa,#333a65);
}

.blog-post_wrapper.image-wrapper.blog-wrapper-list:before {
    display: none;
}

#mt_blog .blog-post_wrapper.blog-wrapper-list{background: #f1f1f1;}

#mt_blog .blog-post_wrapper.blog-wrapper-list .blog-post-image{height: 202px; overflow: hidden; position: relative;}

#mt_blog .blog-post_wrapper.blog-wrapper-list .blog-post-image .post-category{
  position: absolute;
  bottom: 20px;
  left: 30px;
}

#mt_blog .blog-post_wrapper.blog-wrapper-list h2{max-height: 3.2em;}

#mt_blog .blog-post_wrapper.blog-wrapper-list .post-date p{margin-top: 0;}

#mt_blog .blog-post_wrapper.image-wrapper.blog-wrapper-list .post-content{background: #f1f1f1; top: 48%;}

#mt_blog .blog-post_wrapper.image-wrapper.blog-wrapper-list .post-date p a{color: #333a65;}

#mt_blog .item-meta a {
    font-size: 16px;
    font-weight: 700;
    position: relative;
    z-index: 2;
    text-align: left;
    line-height: 1.5;
}

.blog-post_wrapper.image-wrapper.blog-wrapper-list .post-content a {
    background-image: linear-gradient(to right, #333a65, #333a65);
    color: #333a65;
}

#mt_blog .blog-post_wrapper .post-content{position: relative; z-index: 2;}

#mt_blog .blog-post_wrapper h2{
  margin-bottom: 15px;
  line-height: 1.5;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-height: 4.5em;
}  

#mt_blog .blog-post_wrapper .blog-post-image img{
  width: 100%; 
  transition: all ease-in-out 0.5s
}

#mt_blog .blog-post_wrapper:hover img{
  transform: scale(1.1);
  transition: all ease-in-out 0.5s
}

.post-category li{
    display: inline-block;
    padding: 6px 15px;
    border-radius: 26px;
    margin-right: 5px;
}

.post-category li a{
  background:transparent; 
}

#mt_blog .blog-post_wrapper.front-wrapper.bg-gray .post-category li a:hover{
  background: transparent;
}


.cat-yellow{
  background: #fda300;
}

.cat-pink {
    background: #ca00fd;
}

.cat-green {
    background: #3ab308;
}

.cat-blue {
    background: #008fd4;
}

.ads-banner-img img{border-radius:0 26px;}

.width70 {width: 70%!important;}

.width30{width: 30%!important;}

@media(max-width: 991px){
  .width70, .width30{width: 100%!important;}
}

.home-1 #mt_blog .blog-post_wrapper{
    display: flex;
    position: relative;
    margin-bottom: 30px;
    background: #fff;
    overflow: hidden;
    height: inherit;
    box-shadow: 0 0 15px #cccccc30;
    border: 5px solid #fff;
}

.home-1 #mt_blog .blog-post_wrapper h2{max-height: 3em;}

.home-1 #mt_blog .blog-post_wrapper .blog-post-image{
    width: 45%;
    position: relative;
    overflow: hidden;
}

.home-1 #mt_blog .blog-post_wrapper .post-content {
    width: 55%;
    padding:30px 30px 22px;
}

.home-1 #mt_blog .blog-post_wrapper .post-content.post-blank{
  width: 100%;
}

.home-1 #mt_blog .blog-post_wrapper .post-date p a{color: #333a65;}


.widget {
    background: #ffffff;
    border: 5px solid #fff;
    border-radius: 0 26px 0 26px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    box-shadow: 0 0 15px #cccccc30;
}

.widget:last-child{margin-bottom: 0;}

.widget a{
  background-image: linear-gradient(to right, #333a65, #333a65);
  padding-bottom: 5px;
}
.widget-cats{margin-bottom: 3px;}

.widget-cats a {
    font-size: 13px;
    text-transform: uppercase;
}

.widget-title {
    background: linear-gradient(45deg,#e77842,#f7c068);
    padding: 12px 15px;
}

.widget h3{margin-bottom: 0;}

/*widget author*/

.widget.widget-author:before {
    content: '';
    background: linear-gradient(45deg,#e77842,#f7c068);
    height: 22%;
    width: 100%;
    position: absolute;
}

.widget-image {
    position: relative;
    padding-top: 40px;
}

.widget-image img {
    border-radius: 50%;
    height: 120px;
    width: 120px;
    display: inline-block;
    border: 5px solid #fbfbfb;
    box-shadow: 0 0 15px #ccc;
}

.widget-author-content {
    padding:30px 20px;
}

.widget-author-content  .follow_us .social_icons li{padding: 0 5px;}

/*widget category*/

.widget-category ul li{
  padding: 13px 15px; 
  border-bottom: 1px dashed #f1f1f1; 
  width: 100%; 
  margin:0;
  position: relative;
}

.widget-category ul li:last-child{border: none;}

.widget-category ul li:before{
  content: '\f101';
  font-family: 'fontawesome';
  padding-right: 8px;
}

/*widget popular post*/

.widget-popular-post .widget-posts{padding: 15px;} 

.widget-popular-post .widget-posts:nth-child(even){
  background: #fbfbfb;
}

.widget-popular-post .post-thumb{
  float: left;
  max-width: 125px;
  margin-right: 15px;
}

.widget-popular-post .post-thumb img{border-radius: 0 10px 0 10px;}

.widget-popular-post .post-title {
    text-align: left;
}

.widget.widget-popular-post .post-title h4{margin-bottom: 0;}

/*widget tags*/

.tag .widget-tags-main{padding: 0}

h5.bg-orange {
  display: inline-block;
  padding: 8px 12px; 
  border-radius: 0 10px;
  color: #fff; 
  margin: 0;
}

.widget-tags-main {
    padding: 15px 15px 10px;
}

.widget-tags-main a{
  background: #f1f1f1; 
  padding: 8px 12px; 
  border-radius: 0 10px; 
  margin:0 2px 5px 0;
  display: inline-block;
  transition: all 0.4s ease;
}

.widget-tags-main a:hover{
  transition: all 0.4s ease;
  background:linear-gradient(45deg,#e77842,#f7c068); 
  color: #fff;
}

/*widget ads*/
.widget-ads-image{
    padding: 15px 15px 10px;
}

/*tag list*/

#mt_blog.tag-list .blog-post_wrapper{height: 220px!important;}

#mt_blog.tag-list .post-content p {
    line-height: 1.5;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    max-height: 4.5em;
    margin-bottom: 0;
}    


@media(max-width: 1100px){
  #mt_blog .blog-post_wrapper{height: 340px;}

  #mt_blog .blog-post_wrapper h2{font-size: 28px;}

  #mt_blog .blog-post_wrapper.image-wrapper .post-content, #mt_blog .blog-post_wrapper.front-wrapper{
    padding: 30px;
  }
}

@media(max-width: 991px){
   #mt_blog .blog-post_wrapper.image-wrapper.blog-wrapper-list{ height: auto;}
 #mt_blog .blog-post_wrapper.image-wrapper.blog-wrapper-list .post-content{position: relative; width: 100%;}

  section#mt_blog .blog_post_sec.fullwidth{width: 100%;}

  .sidebar{margin-top: 30px; display: inline-block; width: 100%;}
}

@media(max-width: 811px){

  .widget-ads-image img {width: 100%;}
}

@media(max-width: 735px){

  .home-1 #mt_blog .blog-post_wrapper{display: block;}

  .home-1 #mt_blog .blog-post_wrapper .post-content, .home-1 #mt_blog .blog-post_wrapper .blog-post-image{width: 100%; text-align: center;}
}

@media(max-width: 639px){

  #mt_blog .blog-post_wrapper .blog-post-image img{transform: scale(1.5);}

  #mt_blog .blog-post_wrapper h2{font-size: 24px;}
}

@media(max-width: 360px){

}

/* ============================= */
/*          Blog Single          */
/* ============================= */
.blog-detail-main {
    width: 90%;
    background: #fff;
    border-radius: 26px;
    margin: -77px auto 0;
    z-index: 2;
    position: relative;
    padding: 50px 50px 0;
}

.blog-detail-main.cats-detail {
    width: 100%;
    padding: 0;
    background: transparent;
    margin-top: 0px;
}

.blog-detail-main .post_body p {
    font-size: 18px;
    line-height: 1.7;
    margin-bottom: 25px;
}

.blog-detail-main .post_body p img {
    width: 45%;
    float: left;
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 0px 26px 0px 26px;
}

.blog-detail-main .post_body>p:first-child:first-letter {
    font-size: 80px;
    color: #f75656;
    float: left;
    display: block;
    line-height: 1;
    padding-right: 10px;
    font-weight: 400;
}

#blog_main_sec .post_img {margin-bottom: 30px; }

#blog_main_sec .post_item.post_video .post_img{ margin-bottom: 22px;}  

.post_item.post_video .post_img iframe {width: 100%;}

#blog_main_sec .post_img img { width: 100%; }

#blog_main_sec .post_title h3 {
  margin-bottom: 5px;
  margin-top: 0; 
}

#blog_main_sec .post_title ul li, #blog_main_sec .post_title ul li a{color: #ffac00; margin-bottom: 10px; font-size: 14px;}

#blog_main_sec .post_title ul li:before {display: none; }

#blog_main_sec .post_item a.mt_btn_blue{margin-bottom: 0;}

#blog_main_sec .author_box {overflow: hidden; margin-top: 30px; border: 1px solid #f1f1f1; padding: 20px;}

#blog_main_sec .author_box .author_img {float: left;width: 15%; }

#blog_main_sec .author_box .author_img img {
  margin-bottom: 10px;
  width: 100%;
  border-radius:50%; 
}

#blog_main_sec .author_box .author_bio {
  float: left;
  margin-left: 3%;
  width: 75%; 
}

#blog_main_sec .author_box .author_bio h5 {margin-top: 0; margin-bottom: 10px;}

#blog_main_sec .author_box .author_bio p {margin-bottom: 10px; }

#blog_main_sec .author_box .author_bio ul li {
  display: inline-block;
  padding-left: 0;
  padding-right: 15px;
  margin-bottom: 0;
  }

#blog_main_sec .author_box .author_bio ul li:before {display: none; }

#blog_main_sec .author_box .author_bio ul li a {
  color: #444444;
  font-size: 17px;
}

#blog_main_sec .author_box .author_bio ul li a:hover {color: #333a65;transition: all 0.4s ease; }

#blog_main_sec #comments {margin-top: 40px; }

#blog_main_sec #comments .single-post_heading, #blog_main_sec #comments .leave_comment h3, .leave_review .blog_heading_border{
  font-size: 20px;
  color: #444444;
  text-transform: capitalize; 
  padding-bottom: 30px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}

#blog_main_sec #comments .single-post_heading:before{ width: 10%;}

#blog_main_sec #comments .comments-lists:before {display: none; } 

#blog_main_sec #comments .comments-lists .comment, .review-wrap .review-lists li.comment {
  position: relative;
  list-style: none;
  padding-left:98px;
  padding-top: 1px;
  min-height: 100px;
  border-bottom: 1px solid #F8F8F8;
  margin-top: 20px; 
}

#blog_main_sec #comments .comments-lists .comment:last-child {border-bottom: 0px; }

#blog_main_sec #comments .comments-lists .comment .activity_rounded, .review-wrap .review-lists li.comment .activity_rounded {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0; 
}

#blog_main_sec #comments .comments-lists .comment .activity_rounded img, .review-wrap .review-lists li.comment .activity_rounded img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

#blog_main_sec #comments .comments-lists .comment h4 {
  font-size: 15px;
  color: #444444;
  margin-top: 0px;
  text-transform: capitalize; 
}

#blog_main_sec #comments .comments-lists .comment p {
  font-size: 14px;
  color: #777777;
  line-height: 24px; 
}

#blog_main_sec #comments .comments-lists .comment small.date-posted, .review-wrap .review-lists li.comment .comment-body small.date-posted {
  color: #666666;
  font-size: 12px;
  margin-left: 12px;
  border: 1px solid #f1f1f1;
  padding: 5px 10px;
  background: #fbfbfb;
}

#blog_main_sec #comments .comments-lists .comment .comment-body, .review-wrap .review-lists li.comment .comment-body {padding-bottom: 20px; }

#blog_main_sec #comments .comments-lists .comment .comment-body .mt_btn_yellow {
  padding: 4px 15px;
  margin-bottom: 10px;
  display: inline-block; 
}

#blog_main_sec #comments .comments-lists .comment .children {
  overflow: hidden;
  list-style: none;
  padding-left: 0px;
  list-style: none; 
}

#blog_main_sec #comments .comments-lists .comment .children .comment {
  position: relative;
  border-top: 1px solid #F8F8F8;
  border-bottom: 0px;
  margin-top: 0px; 
}
#blog_main_sec #comments .comments-lists .comment .children .comment:before {
  display: none; }

#blog_main_sec #comments .comments-lists .comment .children .comment .activity_rounded {
  margin-top: 20px; }

#blog_main_sec #comments .comments-lists .comment .children .comment .comment-body {
  border: none;
  margin-top: 20px; 
  padding-bottom: 5px;
}
#blog_main_sec #comments .leave_comment h3 {
  font-size: 20px;
  color: #444444;
  text-transform: capitalize; 
}
#blog_main_sec #comments .leave_comment .form-group .form-control, .leave_review .form-group .form-control {height: 40px; }

#blog_main_sec #comments .leave_comment .form-group input[type="text"],
#blog_main_sec #comments .leave_comment .form-group input[type="email"],
.leave_review .form-group input[type="text"],
.leave_review .form-group input[type="email"]
 {
  border: 1px solid #eceaea;
  border-radius: 0px;
  box-shadow: none;
  font-size: 16px;
  color: #1a1a1a;
  padding: 10px 25px;
  margin-bottom: 20px;
}

#blog_main_sec #comments .leave_comment .form-group input[type="text"]:hover, #blog_main_sec #comments .leave_comment .form-group input[type="text"]:focus,
#blog_main_sec #comments .leave_comment .form-group input[type="email"]:hover,
#blog_main_sec #comments .leave_comment .form-group input[type="email"]:focus {
  border: 1px solid #333a65; 
}

#blog_main_sec #comments .leave_comment textarea, .leave_review textarea {
  resize: vertical;
  min-height: 180px;
  border: none;
  border: 1px solid #eceaea;
  border-radius: 0px;
  box-shadow: none;
  font-size: 16px;
  color: #1a1a1a;
  padding: 10px 25px;
  margin-bottom: 20px; 
}

#blog_main_sec #comments .leave_comment textarea:hover, #blog_main_sec #comments .leave_comment textarea:focus {
  border: 1px solid #333a65; 
}

 #comments a.btn-blog {
    padding: 3px 15px;
}

.page #mt_blog{
  margin: 0;
  padding-bottom: 20px;
}

@media(max-width: 1100px){
  .blog-detail-main{width: 100%;}
}

@media(max-width: 639px){
  .blog-detail-main{margin: 0; padding: 0;}
}

@media(max-width: 567px){

  #blog_main_sec #comments .comments-lists .comment, .review-wrap .review-lists li.comment{padding: 0; text-align: center;}

  #blog_main_sec #comments .comments-lists .comment .activity_rounded, 
  .review-wrap .review-lists li.comment .activity_rounded{position: inherit; margin-bottom: 15px;}

  #blog_main_sec #comments .comments-lists .comment h4{text-align: center;}

  #blog_main_sec #comments .comments-lists .comment small.date-posted, 
  .review-wrap .review-lists li.comment .comment-body small.date-posted{
    display: block; 
    float: none!important;
    margin: 15px 0 0;
  }

  #comments a.btn-blog{float: none!important;}

  .blog-detail-main .post_body p{font-size: 15px;}
}

/* ====================== */
/*         Instagram         */
/* ====================== */

.mt_instagram{position: relative;}

.mt_instagram .sectio-title {
    position: absolute;
    z-index: 1;
    background: #fff;
    padding: 15px 25px;
    bottom: 45%;
    left: 38.5%;
    border: 1px solid #333a65;
    border-radius: 26px;
}

.mt_instagram .col-sm-2{
  padding: 0 5px 10px;
}

.mt_instagram img{
  filter: grayscale(100%);
  border-radius: 26px;
  transition: all ease-in-out 0.5s;
}

.mt_instagram img:hover{
  filter: grayscale(0);
  transition: all ease-in-out 0.5s;
}

@media(max-width: 991px){
  .mt_instagram{text-align: center;}

  .mt_instagram .sectio-title{
    position: inherit;
    bottom: inherit;
    left: inherit;
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
  }

  .mt_instagram .sectio-title h3{font-size: 18px;}
}

@media(max-width: 767px){
  .mt_instagram .col-sm-2{padding: 0 15px 30px;}
  .mt_instagram .sectio-title{margin-top: 0;}
  .mt_instagram img{width: 100%;}
}


/* ====================== */
/*         Footer         */
/* ====================== */

#mt_footer {
    position: relative;
    padding-top: 0px;
}
#mt_footer .mt_footer_menu ul li{padding: 0 5px; margin: 0;} 

#mt_footer .mt_footer_menu ul li a{
  color: #333a65; 
  background: linear-gradient(to right, #333a65, #333a65);
  background-position: left 87%;
  background-repeat: no-repeat;
  transition: all ease-in-out 0.5s;
  background-size: 0px 3px;
  padding-bottom: 10px;
  font-weight:600;
}

#mt_footer .mt_footer_menu ul li a:hover {
  text-decoration: none !important;
  background-size: 100% 3px;
  transition: all ease-in-out 0.5s;
}

#mt_footer .mt_footer_copy {
  text-align: center;
  padding:0px 0 30px;
}

.follow_us a {display: inline-block;}

.follow_us .social_icons li {
  margin-bottom: 0;
  padding-right: 15px; 
}
.follow_us .social_icons li:before {
  display: none; }

.follow_us .social_icons li a {
    color: #fff;
    margin-bottom: 0;
    background: #333a65;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    line-height: 2.6;
    text-align: center;
}

.follow_us .social_icons li a:hover{opacity: 0.8;}


/*Style 2*/

footer#mt_footer.mt_footer_style1{
    margin-top: 34px;
}

.mt_footer_col{margin-bottom: 26px;}

.mt_footer_col p{margin: 0;}

#mt_footer .mt_footer_col h3{
  margin-bottom: 20px;
}

#mt_footer .mt_footer_list li{margin-bottom: 5px;}

#mt_footer .mt_footer_list li a {
  display: inline-block;
  padding-bottom: 5px;
  font-size: 14px; 
  background-image: linear-gradient(to right, #333a65, #333a65);
}

#mt_footer .mt_footer_list li a i{padding-right: 5px;}

#mt_footer .mt_footer_gallery .col-sm-4.col-xs-6 {padding: 0 2px 4px;}

#mt_footer .mt_footer_gallery {padding: 0 11px;}

.mt_footer_gallery a img {border-radius: 10px; overflow: hidden;}

#mt_footer.mt_footer_style1 .mt_footer_copy{float: left; width: 100%;}

#mt_footer.mt_footer_style1 .copy_txt{padding-top: 8px;}

@media(max-width: 991px){
  .mt_footer_col .col-md-3.col-sm-6.col-xs-12{margin-bottom: 30px;}

  #mt_footer .mt_footer_list li{width: 100%;}

  #mt_footer .mt_footer_gallery img{width: 100%;}

  .mt_footer_col{margin-bottom: 16px;}
}

@media(max-width: 767px){
  #mt_footer .mt_footer_gallery{padding: 0;}
  #mt_footer .mt_footer_gallery .col-sm-4.col-xs-6{padding: 0 15px 30px;}
  .mt_footer_col .col-md-3.col-sm-6.col-xs-12:last-child{margin: 0;}

  .mt_footer_col{margin-bottom: 5px;}
}

@media(max-width: 735px){
  #mt_footer.mt_footer_style1 .copy_txt{width: 100%; margin-bottom: 15px;}
  .follow_us.pull-right {float: none!important;}
}

/* ================================== */
/*            about Us         */
/* ================================== */

.about-us{
  background: url(../images/about-bg.jpg) no-repeat;
  background-position: bottom center;
  background-size: 100%;
  padding-bottom: 40px;
}

.about-content {
    border: 1px solid #f1f1f1;
    padding: 30px;
}

.about-content p{margin: 0;}

.about-sep{display: inline-block; width: 100%;}

@media(max-width: 991px){
  .about-content, .about-image{margin-bottom: 30px;}

  blockquote.mar-top-30{margin-top: 0;}
}

/* ================================== */
/*            Contact Us         */
/* ================================== */

.contact-location ul li{display: block;}

.contact-location ul li i{padding-right: 5px;}

@media(max-width: 991px){
  .contact-about.footer-margin{margin-top: 30px;}
}

/* ====================== */
/*         Error Page     */
/* ====================== */

section.error{margin-top: 107px;}

.error-box{
  height: 200px;
  width: 200px;
  margin: 0 auto;
  background: #f1f1f1;
  border-radius: 50%;
  padding: 10px;
  margin-bottom: 30px;
  box-shadow: 0 0 10px #333;
}
.error-box-inner{
  height: 180px;
  width: 180px;
  margin: 0 auto;
  background: #333a65;
  border-radius: 50%;
  border: 2px dashed #fff;
}
.error-box-inner h1{
  font-size: 80px;
  line-height: 2.2;
  color: #fff;
  margin: 0;
}
 h1.not-found{
  text-transform: uppercase;margin: 30px 0 0;
}
.error-content a {
    color: #ffffff;
    text-decoration: underline;
}
@media(max-width: 991px){
  section.error{margin-top: 0;}
}

/* ================================== */
/*            Back to Top          */
/* ================================== */

.back-to-top {
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    display:none;
    background-color:#6d77aa; 
    border:none;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    text-align: center;
    padding: 10px 0 0;
    z-index: 2;
    color: #fff!important;
}

#back-to-top:hover {
    opacity: 0.8;
}


/* ====================== */
/*         Shop         */
/* ====================== */

.section_heading{margin:0 0px 4rem; padding-right: 30%;}

.section_heading h2.section_title{
    position: relative;
    padding-left: 25px;
}

.section_heading h2.section_title:before {
    content: '';
    height: 100%;
    width: 3px;
    background: #D60D45;
    position: absolute;
    top: 0;
    left: 0;
}

.our_store{ padding: 50px 0 50px;}

.our_store .col-sm-3.col-xs-12{padding: 0 10px;}

.rt-product-wrapper {
    border: 1px solid #f1f1f1;
    transition: all 0.5s;
}

.rt-product-wrapper:hover {
    border-bottom-color: #6d77aa;
    transition: all 0.5s;
    box-shadow: 0 0 10px #cccccc52;
}

.rt-product-wrapper .product-image img{
  transition: all 0.5s;
}

.rt-product-wrapper:hover .product-image img{
  transform: rotate(8deg) scale(1.2);
  transition: all 0.5s;
}

.product-thumbnail-wrapper {
    position: relative;
    overflow:hidden;
}

.rt-product-wrapper h3.product_title{font-size: 21px;}

.rt-product-wrapper .product-label span {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px 15px;
    background: #6d77aa;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
}

.rt-product-wrapper .product-label span.hot{
  background:#e87f45;
  text-align: center;
  left: inherit;
  right: 0;
}

.rt-product-meta-wrapper {
    text-align: center;
    background: #fff;
    padding: 28px 15px 30px;
    border-top: 1px solid #f1f1f1;
}

.product-categories , .product-categories a{color: #D60D45; font-size: 14px;}


.rt-product-meta-wrapper del{opacity: 0.6; font-size: 16px;}

.rt-product-meta-wrapper ins {
    padding-left: 15px;
    text-decoration: none;
    font-size: 16px;
}

.rt-cartprice-wrapper span.price{
    display: inline-block;
    width: 100%;
}

.rt-product-meta-wrapper .btn_red{margin-bottom: 0;}

.slick-slider.row{
  margin: 0 -15px;
}

.slider-thumbs .slick-list{background: #333;}

.our_store .slick-prev, .our_store .slick-next {
    top: -46px;
    border: 1px solid #ccc;
    background: #fff;
    text-align: center;
    height: 35px;
    width: 35px;
    margin-top: 0;
    line-height: inherit;
}

.our_store .slick-prev {
    right: 60px;
    left: inherit;
}

.our_store .slick-next{right: 15px;}

.our_store .slick-prev:before, .our_store  .slick-next:before{
  font-size: 17px;
  color: #666;
  background: none;
}

.our_store .slick-prev:hover, .our_store .slick-prev:focus, .our_store .slick-next:hover, .our_store .slick-next:focus,
.our_store .slick-prev:visited, .our_store .slick-next:visited{
    color: #666;
    outline: none;
    background: #D60D45;
    border-color:#D60D45;
    transition: all 0.5s; 
}

.our_store .slick-prev:hover:before, .our_store .slick-prev:focus:before, 
.our_store .slick-next:hover:before, .our_store .slick-next:focus:before{
  color: #fff;
  transition: all 0.5s;
}


@media(max-width: 1100px){
  .rt-product-meta-wrapper{padding:28px 5px 30px;}
}

@media(max-width: 991px){
  .rt-product-wrapper .product-image img{width: 100%;}

  .rt-product-wrapper{margin-bottom: 30px;}

  .slider-shop .rt-product-wrapper{margin-bottom: 0px;}
}

/*store detail*/

.store-detail{padding: 50px 0 0px;}

.store-detail .slider.slider-thumbs{margin-top: 2px;}

.store-detail .slider.slider-thumbs .slick-slide{opacity: 0.5;}

.store-detail .slider.slider-thumbs .slick-slide.slick-active{opacity: 1;}

.store-detail .slider.slider-thumbs .slick-prev, .store-detail .slider.slider-thumbs .slick-next{top: 45%; background: none;}

.store-detail .slider.slider-thumbs .slick-prev{left: 0;}

.store-detail .slider.slider-thumbs .slick-next{right: 0;}

.store-detail .slider.slider-thumbs .slick-prev:before, .store-detail .slider.slider-thumbs .slick-next:before{color:#fff; opacity: 1;background: none;}

.store-detail .rt-product-meta-wrapper{background: transparent; text-align: left; padding: 0; border: none;}

.store-detail .quantity-buttons{display: inline-block;}

.store-detail span.rt-price-amount{font-weight: 600; font-size: 18px;}

.single-product-summary .product-details_short-description {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}

.single-product-summary .product-details_short-description p{margin-bottom: 0;}

.single-product-summary form.cart {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}

span.quantity-buttons input {
    width: 60%;
    text-align: left;
    margin: 0;
    height: 42px;
}

input.quantity-input {
    border: 1px solid #ccc;
    text-align: center;
    margin-bottom: 0;
    padding: 12px;
}
.single-product-summary .product_meta>span {
    display: block;
    margin: 10px 0;
}

.single-product-summary .product_meta a, .single-product-summary .product_meta span {
    font-size: 14px;
    line-height: 1.82em;
    letter-spacing: .12em;
    font-weight: 600;
    text-transform: uppercase;
    color: #333;
}

.single-product-summary .product_meta a:hover{color:#eb262a;}

#store-tabs{
  background: #fff;
  padding: 30px 0 0;
}

#store-tab-main .nav-tabs {
    border-color:#f1f1f1;
}

#store-tab-main .nav-tabs>li{margin: 0;}

#store-tab-main .nav-tabs>li>a{
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 12px 17px 12px;
    font-size: 18px;
    text-align: center;
    font-weight: 300;
    color: #6d77aa;
    background: transparent;
    border-radius: 0;
    margin: 0;
    line-height: inherit;
    border: none;
}

#store-tab-main .nav-tabs>li>a:hover, #store-tab-main .nav-tabs>li.active>a, 
#store-tab-main .nav-tabs>li.active>a, #store-tab-main .nav-tabs>li.active>a:focus{
    color: #fff!important;
    border: none;
    background: #6d77aa;
}

#store-tab-main  .tab-content{
    border: 1px solid #f1f1f1;
    padding: 30px;
    border-top: 1px solid transparent;
}

#store-tab-main .tab-content p{margin-bottom: 0;}

@media(max-width: 991px){
  .single-product-summary{margin-top: 15px;}
}

@media(max-width: 767px){
  .entry-summary{
    margin-top: 15px;
  }
}
.shopping-main {
    position: absolute;
    right: 90px;
}

.shopping-cart-holder .header-cart {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    padding: 25px 0 0;
}

.shopping-cart-holder .header-cart span.cart-icon path {
    fill: #fff;
}

#inner-navigation .navbar-sticky-in.navbar-default .shopping-cart-holder .header-cart span.cart-icon path,
.home-2 .shopping-cart-holder .header-cart span.cart-icon path{
  fill: #000;
}

.shopping-cart-holder .header-cart:hover {
    color: #818491;
}

.shopping-cart-holder .header-cart .cart-number {
    position: relative;
    top: 0px;
    right: 10px;
    font-weight: 500;
    font-size: 10px;
    border-radius: 100%;
    background-color: #D60D45;
    color: #fff;
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 15px;
    display: inline-block;
}

.shopping-cart-dropdown {
    position: absolute;
    top: 75px;
    right:0px;
    width: 260px;
    background-color: #fff;
    text-align: left;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    box-sizing: border-box;
    -webkit-transition: all .35s cubic-bezier(.53,.01,.18,1);
    -moz-transition: all .35s cubic-bezier(.53,.01,.18,1);
    transition: all .35s cubic-bezier(.53,.01,.18,1);
    box-shadow: 0 5px 5px 0 rgba(0,0,0,.03);
}

.shopping-cart-holder:hover .shopping-cart-dropdown {
    opacity: 1;
    visibility: visible;
}

.shopping-cart-dropdown ul {
    margin: 22px 20px;
    padding: 0;
}


.shopping-cart-dropdown ul li {
    position: relative;
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
    padding: 0px 0 5px;
    box-sizing: border-box;
}

.shopping-cart-dropdown ul li:first-child {
    padding-top: 0;
}

.shopping-cart-dropdown ul li:last-child {
    padding-bottom: 0;
}

.shopping-cart-dropdown .item-image-holder {
    position: relative;
    display: table-cell;
    height: 100%;
    width: 75px;
}

.shopping-cart-dropdown .item-image-holder img {
    display: block;
    width: 75px;
    height: 75px;
    border-radius: 50%;
}

.shopping-cart-dropdown .item-info-holder {
    position: relative;
    display: table-cell;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    padding-left: 14px;
}

.shopping-cart-dropdown .item-info-holder .product-title {
    display: block;
    margin: 0;
    padding: 0 20px 0 0;
    box-sizing: border-box;
    line-height: 1.2em;
}

.shopping-cart-dropdown .item-info-holder .rt-price-amount, .shopping-cart-dropdown .item-info-holder .rt-quantity {
    display: inline-block;
    font-size: 16px;
    color: #c78665;
    font-weight: 600;
    margin-top: 3px;
}

.shopping-cart-dropdown .item-info-holder .remove i{
    position: absolute;
    top: -1px;
    right: 0;
    font-size: 22px;
    line-height: 1;
}

.shopping-cart-dropdown .cart-bottom {
    padding: 16px 0 0;
    border-top: 1px solid #e1e1e1;
}

.shopping-cart-dropdown .cart-bottom .subtotal-holder {
    position: relative;
    display: table;
    width: 100%;
    margin: 0 0 17px;
}

.shopping-cart-dropdown .cart-bottom a.mt_btn_yellow{
    padding: 5px 18px;
    width: 48%;
    float: left;
    color: #fff;
    margin: 0 1%;
}

.shopping-cart-dropdown .cart-bottom a:hover{
  opacity: 0.9;
  color: #fff;
}

@media(max-width: 991px){
  .navbar-sticky-in .shopping-cart-holder .header-cart{padding-top: 13px;}
}

@media(max-width: 811px){
  .shopping-main{right: 30px;}
}

@media(max-width: 639px){
  #store-tab-main .nav-tabs>li, #store-tab-main .nav-tabs>li>a{width: 100%;}
}

/*cart*/

.cart-table-list thead{width: 100%;}

.cart-table-list th, .cart-table-list td{text-align: center;}

.cart-table-list .product-thumbnail img {
    width: 30%;
    margin-right: 15px;
}

.cart-inner .checkout-order{margin: 30px 0 20px;}

.cart-table-list table td.actions .coupon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    float: left;
}

.cart-table-list .coupon input[type=text]{
    width: 250px;
    margin-left: 22px;
    box-shadow: none;
    border: 1px solid #f1f1f1;
    background: #fff;
    height: 35px;
    vertical-align: top;
    margin-bottom: 0;
    padding-left: 10px;
}

.cart-table-list table td.actions .button {
    margin: 0 0 0 22px;
}

.coupon .btn-blog {
    padding: 6px 12px;
    border-width: 1px;
}


@media(max-width: 991px){

  .cart-table-list .quantity-buttons{position: relative;}
  
}

@media(max-width: 568px){
  .cart-table-list table.shop_table.rt-checkout-review-order-table{display: inline-block; overflow-x: scroll;}
  span.quantity-buttons input{
    width: 100%;
  }
}

@media(max-width: 567px){
  .coupon .btn-blog{margin-top: 10px;}
}


.login-form .comment-btn{
    border-bottom: 1px dashed #f1f1f1;
    padding-bottom: 20px;
  }

.comment-btn a{display: inline-block;}

@media(max-width: 991px){
  .register-form{margin-top: 30px;}
}

/*checkout*/
.checkout-inner .checkout-coupon{
    position: relative;
    display: block;
    padding: 30px 30px;
    list-style: none;
    background-color: transparent;
    border: 1px solid #e1e1e1;
    width: 100%;
    font-size: 16px;
}

.checkout-inner .checkout-coupon a{float: right; color: #6d77aa;}

.checkout-info, .checkout-order{margin-bottom: 20px;}

.checkout-info label span{padding-left: 5px;}

.checkout-info label abbr[data-original-title], .checkout-infolabel abbr[title]{border: none;}

.checkout-info .form-control, .checkout-info textarea.form-control{
  height: 42px;
  box-shadow: none;
  background: #fff;
  border-color: #eeeeee;
  border-radius: 0px;
}

.checkout-info textarea.form-control{min-height: 174px;}

table.shop_table.rt-checkout-review-order-table{width: 100%;}

table.shop_table.rt-checkout-review-order-table tr{border: 1px solid #f1f1f1;}

table.shop_table.rt-checkout-review-order-table tr th, 
table.shop_table.rt-checkout-review-order-table tr td{padding:12px 15px; font-size: 15px; border: 1px solid #f1f1f1;}

table.shop_table.rt-checkout-review-order-table thead, table.shop_table.rt-checkout-review-order-table tr.order-total{
  background: #fbfbfb;
}

@media(max-width: 500px){
  .checkout-inner .checkout-coupon a{
    display: block;float: none;
  }
}

/*account*/

.account-inner.lost-pswrd{width: 60%; margin: 0 auto;}

.account-inner span{color: #666; font-size: 15px;}

.account-inner button.mt_btn_yellow{margin-right: 15px;}

.account-inner input[type="checkbox"]{
  display: inline-block;
}

p.lt-pswrd{margin-bottom: 20px;}
p.lost_password{
  margin: 15px 0;
}
.fp-content label{
  margin-bottom: 15px;
}

/* ====================== */
/*      FAQ       */
/* ====================== */

section#mt_faq .faq-detail{width: 80%; margin: 0 auto;}

.accrodion-grp .accrodion + .accrodion {
  margin-top: 10px;
}
.accrodion-grp .accrodion .accrodion-title {
  border: 1px solid #DEE0E1;
  background: #FFFFFF;
  padding: 14.5px 25px;
  cursor: pointer;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}
.accrodion-grp .accrodion .accrodion-title h4 {
  margin: 0;
  position: relative;
  color: #333;
  font-weight: 500;
}
.accrodion-grp .accrodion .accrodion-title h4:before {
  content: '\f107';
  font-family: 'FontAwesome';
  color: #222222;
  font-size: 20px;
  position: absolute;
  top: 50%;
  right: 0px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.accrodion-grp .accrodion .accrodion-content {
  padding-top: 10px;
}
.accrodion-grp .accrodion .accrodion-content .inner {
  padding: 20px 0px;
}
.accrodion-grp .accrodion .accrodion-content p {
  margin: 0;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: .01em;
  color: #74787D;
}
.accrodion-grp .accrodion.active .accrodion-title {
  background: #333a65;
  border-color:#333a65;
}
.accrodion-grp .accrodion.active .accrodion-title h4:before {
  content: '\f106';
  color: #fff;
}

.accrodion-grp .accrodion.active .accrodion-title h4{
  color: #fff;
}

/* ====================== */
/*         Coming soon      */
/* ====================== */

body.coming{
  background: url(../images/pagebanner.jpg) no-repeat;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  position: relative;
  background-size: cover;
}

#coming .container{position: relative; z-index: 2;}

#coming .coming-main{
    width: 70%;
    margin: 0 auto;
}

#coming .coming-content img{
    padding: 10px 20px;
    background: #fff;
    border-radius: 0 26px;
    box-shadow: 0 0 15px #00000040;
}

#coming .coming-content p{font-size: 21px;}

#coming .count-content #demo {
    position: relative;
    font-size: 58px;
    font-weight:600;
    color: #fff;
    display: inline-block;
    line-height: 2.0;
}

#coming .count-content #demo span {
    font-size: 24px;
    padding: 0 10px;
}

#coming .mailpoet_form {
    position: relative;
    overflow: hidden;
    background: #fff;
    height: 42px;
    width: 70%;
    margin: 0 auto;
}

#coming input.mailpoet_submit {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0;
    padding: 9px 20px;
}

#coming input.btn-blog {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: #2a2f52;
    color: #fff;
    font-size: 16px;
}

#coming .follow_us .social_icons li a {
    color: #333a65;
    background: #fff;
  }

@media(max-width: 911px){

  #coming .count-content #demo{font-size: 42px;}

  #coming .mailpoet_form{width: 100%; display: inline-block;}
}

@media(max-width: 811px) and (min-width: 768px){
  #coming{height:100vh }
}

@media(max-width: 767px){
  body.coming:before{display: none;}

  #coming .count-content{padding: 0;}

  .coming-main{text-align: center;}

  .coming-content h1, .coming-content p{color: #fff;}

  .act-counter{padding: 15px;}

  #coming .coming-main{width: 100%; display: inline-block;}
}

@media(max-width: 480px){

  #coming .count-content{margin-top: 0;}

  #coming .mailpoet_form{overflow: visible;}

  #coming input.mailpoet_submit{position: inherit;}
}


