/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/functions";
@import "./assets/scss/variables";
@import "./assets/scss/breakpoints";
@import "./assets/scss/spacing";
@import "./assets/scss/gap";
@import "./assets/scss/mixins/respond-to";
@import "./assets/scss/toggle";

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 15px;
  color: #2a2f52;
  font-family: "Poppins", sans-serif;
  margin-top: 0;
  font-weight: 700;
  line-height: 1.5;
}

.btn {
  line-height: 1;
}

.p-component {
  font-family: "Poppins", sans-serif !important;
}
.p-card {
  margin-bottom: 0.8rem;
  background-color: transparent;
  .p-card-body,
  .p-card-header {
    padding: 0.5rem 1rem 0 1rem !important;
  }
}

.border-danger {
  border-color: #a94442 !important;
}

.NgxEditor__Content {
  min-height: 200px;
}

.dashboard-nav-header-min {
  padding: 2rem;
  margin-bottom: 50px;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 6%);
  .dashboard-logo {
    img {
      max-width: 180px;
    }
  }
}

.bg-danger {
  background-color: #dc3545 !important;
}

// *---------------------------------------------------------------
.respose-input {
  display: flex;
  button {
    width: 100px;
  }
  input {
    width: calc(100% - 100px);
    margin-bottom: 0 !important;
    margin-right: 0.5rem;
  }
}

.response-items {
  input[type="checkbox"] {
    position: absolute;
    left: -1000em;
  }

  .response-label {
    display: flex;
    align-items: center;

    label {
      font-size: 1.1rem !important;
      width: calc(100% - 2.5rem);
      border-right: 1px solid rgb(194, 194, 194);
      cursor: pointer;
    }

    i {
      visibility: hidden;
      font-size: 1.1rem !important;
      padding-left: 0.5rem;
      color: #333a65;
    }
    i.delete {
      visibility: visible;
    }
  }
  input[type="checkbox"]:checked ~ .response-label {
    i {
      visibility: visible;
    }
  }
}

.post_body {
  .subtitle {
    font-size: 60px !important;
    color: #f75656 !important;
    line-height: 1 !important;
    padding-right: 10px;
    font-weight: 400;
  }
}

.NgxEditor__MenuBar {
  flex-wrap: wrap;
}

// ----------------------------------------------------------------

.container-images {
  width: 100%;
  @include gap;
  .input-images {
    position: absolute;
    left: -4000em;
  }
  .image {
    @include gap-separation;
    position: relative;
    width: calc(33.33333333% - 0.5rem);
    padding: calc(33.33333333% - 0.5rem) 0 0 0;
    box-sizing: border-box;
    border: 2px solid $primary;
    border-radius: 8px;

    //margin: 0;
    i {
      position: absolute;
      font-size: 2rem;
      line-height: 1;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      cursor: pointer;
    }
    i.add {
      z-index: 100;
    }
    i.trash {
      z-index: 300;
      padding: 0.5rem;
      border-radius: 4px;
      background-color: rgba($gray-700, 0.8);
      color: white;
    }

    img {
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      object-fit: cover;
      z-index: 200;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
    @include respond-sm {
      width: calc(25% - 0.5rem);
      padding-top: calc(25% - 0.5rem);
    }
    @include respond-md {
      width: calc(20% - 0.5rem);
      padding-top: calc(20% - 0.5rem);
    }
    @include respond-xl {
      width: calc(16.66666% - 0.5rem);
      padding-top: calc(16.66666% - 0.5rem);
    }
  }
}

.bg-orange {
  background-color: #e77842;
  background-image: linear-gradient(45deg, #e77842, #f7c068);
}

.docs-links {
  word-break: break-all;
}
